import React, { Component }  from 'react'
import Slider from "react-slick";
import magic from '../../../assets/Images/netMagic.svg'
import pradeep from '../../../assets/Images/pradeep.svg'
import kannan from '../../../assets/Images/kannan.svg'
import testi from '../../../assets/Images/test.svg'
import monials from '../../../assets/Images/monilas.svg'
import './style.css'

const Testimonials = () => {

    const settings = {
        dots: true,
      infinite: true,
   
      speed: 500,
      className: "center",
      slidesToShow: 3,
      slidesToScroll: 1,
      centerPadding: "60px",
      centerMargin: "60px",
      responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                initialSlide: 2
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
      };



  return (
        <>
            <div className='testimonials'>
                <div className='container-fluid'>
                    <h3 className='testimonial-heading'>Testimonials</h3>
                    <p className='testimonial-paragraph'>Our clients are the most valuable thing we have.</p>
                        <span><img src={testi} alt="arrrow" className='px-4'/></span>
                        <div className='container'>
                            <Slider {...settings}>
                                  <div className='testimonial-card-section'>
                                      <div className='card parent-profile-box '>
                                          <figure className='d-flex'>
                                              <img src={kannan} alt='image' />
                                              <h3 className='slider-package-testimonials'>Mind Tree </h3>
                                          </figure>
                                          <p>We are happy to mention that the team has completed the work within the stipulated time, quality and budget. To specifically mentioned that the team has worked the highest safety standards`</p>
                                          <h4 className='package-silde-heading4'>Padmanabhan L Kannan</h4>
                                      </div>
                                  </div>
                                  <div className='testimonial-card-section'>
                                  <div className='card parent-profile-box '>
                                        <figure className='d-flex'>
                                            <img src={pradeep} alt='image' />
                                            <h3 className='slider-package-testimonials'>HEWLETT PACKARD </h3>
                                        </figure>
                                        <p >We are happy to mention that the team has completed the work within the stipulated time, quality and budget. To specifically mentioned that the team has worked the highest safety standards" </p>
                                        <h4 className='package-silde-heading4'>Pradeep</h4>
                                    </div>    
                                  </div>
                                  <div className='testimonial-card-section'>
                                  <div className='card parent-profile-box '>
                                        <figure className='d-flex'>
                                            <img src={magic} alt='image' />
                                            <h3 className='slider-package-testimonials'>NetMagic </h3>
                                        </figure>
                                        <p>We are happy to mention that the team has completed the work within the stipulated time, quality and budget. To specifically mentioned that the team has worked the highest safety standards</p>
                                        <h4 className='package-silde-heading4'>Netmagic IT services Pvt ltd</h4>
                                    </div>
                                    </div>                                      
                                   
                                    <div className='testimonial-card-section'>
                                    <div className='card parent-profile-box '>
                                            <figure className='d-flex'>
                                                <img src={kannan} alt='image' />
                                                <h3 className='slider-package-testimonials'>Mind Tree </h3>
                                            </figure>
                                            <p>We are happy to mention that the team has completed the work within the stipulated time, quality and budget. To specifically mentioned that the team has worked the highest safety standards</p>
                                            <h4 className='package-silde-heading4'>Padmanabhan L Kannan</h4>
                                        </div>
                                    </div>

                                        
                            </Slider>
                        </div>  
                    </div>
                    <div className='text-end px-5'><img src={monials} alt="arrrow" /></div>
                </div>
           
        </>
  )
}

export default Testimonials;



