import React from 'react'
import BannerImage from '../../../assets/Images/aboutPage.png'
import Arrow from '../../../assets/Icons/Arrow.svg'
import './style.css'

const BannerAbout = () => {
  return (
    <>
        <div className='banner-about-section'>
            <div className='about-image-content container-fluid p-0'>
                <img src={BannerImage} alt="BannerImage" className='BannerImage'/>
                <div className='image-overlab-content'>
                    <h2 className='about-page-heading'>Meet out team of <br />Innovative Engineers</h2>
                    <p className='about-page-paragraph'>Lorem ipsum dolor sit amet, consectetur adipiscing <br />elit, sed do eiusmod tempor incididunt ut labore</p>
                    <img src={Arrow} alt="arrow" className='image-arrow' />
                </div>
            </div>
        </div>
    </>
  )
}

export default BannerAbout;