import React from 'react'
import BannerImage from '../../../assets/Images/BannerimageResize.png'
import Arrow from '../../../assets/Icons/Arrow.svg'
import './style.css';

const Banner = () => {
  return (
    <>
        <div>
              <div id="demo" className="carousel slide" data-bs-ride="carousel">    
                  <div className="carousel-indicators carousel-icon-dots">
                      <button type="button" data-bs-target="#demo" data-bs-slide-to="0" className="active carousel-slide-icon"></button>
                      <button type="button" data-bs-target="#demo" data-bs-slide-to="1"></button>
                      <button type="button" data-bs-target="#demo" data-bs-slide-to="2"></button>
                  </div>

                 
                  <div className="carousel-inner">
                      <div className="carousel-item active">
                          <img src={BannerImage} alt="Los Angeles" className="d-block banner-image"  />
                              <div className="carousel-caption carousel-option-change">
                                  <h1 className='carousel-header-menu'>Working towards a sustainable<br />lifestyle, responsibly</h1>
                                  <p className='carousel-parah-menu'>Lorem ipsum dolor sit amet, consectetur adipiscing<br /> elit, sed do eiusmod tempor incididunt ut labore</p>
                                    <img src={Arrow} alt="arrow" className='image-arrow' />
                              </div>
                      </div>
                      <div className="carousel-item">
                          <img src={BannerImage} alt="Chicago" className="d-block banner-image"  />
                              <div className="carousel-caption carousel-option-change">
                              <h1  className='carousel-header-menu'>Working towards a sustainable<br />lifestyle, responsibly</h1>
                                  <p className='carousel-parah-menu'>Lorem ipsum dolor sit amet, consectetur adipiscing<br /> elit, sed do eiusmod tempor incididunt ut labore</p>
                                    <img src={Arrow} alt="arrow" className='image-arrow' />
                              </div>
                      </div>
                      <div className="carousel-item">
                          <img src={BannerImage} alt="New York" className="d-block banner-image" />
                              <div className="carousel-caption carousel-option-change">
                              <h1  className='carousel-header-menu'>Working towards a sustainable<br />lifestyle, responsibly</h1>
                                  <p className='carousel-parah-menu'>Lorem ipsum dolor sit amet, consectetur adipiscing<br /> elit, sed do eiusmod tempor incididunt ut labore</p>
                                    <img src={Arrow} alt="arrow" className='image-arrow' />
                              </div>
                      </div>
                  </div>
              </div>

        </div>
    </>
  )
}

export default Banner;