import React from 'react'
import './style.css'

const Meet = () => {
  return (
    <>
        <div className='Meet-section '>
              <div className='container'>
                  <h2 className='meet-heading-section'>Meet Us</h2>
                  <div className='address-content'>
                      <div className='row'>
                          <div className="col-lg-3 col-md-6 col-sm-12 p-0" >
                              <div className='mumbai-section'>
                                  <h5>MUMBAI</h5>
                                  <p>1st Floor,Ganga House, Above Kotak Mahindra Bank, LBS Marg,Vikhroli (West), Mumbai - 400083</p>
                                  <p>Ph: 9876543210</p>
                              </div>
                          </div>
                          <div className="col-lg-3 col-md-6 col-sm-12 p-0" >
                              <div className='mumbai-section'>
                                  <h5>BANGALORE</h5>
                                  <p>No-3, 02nd Floor 1st Block, Talacauvery Nagar, Vibhutipura, Basavanagar, Bangalore - 560037</p>
                                  <p>Ph: 9876543210</p>
                              </div>
                          </div>
                          <div className="col-lg-3 col-md-6 col-sm-12 p-0" >
                              <div className='mumbai-section'>
                                  <h5>CHENNAI</h5>
                                  <p>
                                  No-3, 02nd Floor 1st Block, Talacauvery Nagar, Vibhutipura, Basavanagar, Chennai- 600087
                                  </p>
                                  <p>Ph: 9876543210</p>
                              </div>
                          </div>
                          <div className="col-lg-3 col-md-6 col-sm-12 p-0" >
                              <div className='mumbai-section'>
                                  <h5>PUNE</h5>
                                  <p>No-3, 02nd Floor 1st Block, Talacauvery Nagar, Vibhutipura, Basavanagar, Pune - 411 045</p>
                                  <p>Ph: 9876543210</p>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
        </div>
    </>
  )
}

export default Meet;
