import React from 'react'
import CardImage from '../../assets/Images/cardImage.png'
import ArrowMark from '../../assets/Icons/ArrowMark.svg'
import './style.css'


const Card = () => {
  return(
    <>
        <div className='container-fluid'>
            <div className='card-projects'>
            <h3 className='our-heading4-content'>Recent Projects</h3>
                <div className="card-projects-carousel">
                    <div className='row'>
                        <div className="col-lg-4 col-md-6">
                            <div className="card image-devide-contant" >      
                                <img className="card-projects-image" src={CardImage} alt="Card image" />
                                <div className="card-body contant-right-side">
                                    <h4 className="card-title-name">Project name</h4>
                                    <p className="card-parah-name">Location: Place name</p>
                                    <p className="card-text">Description:<br />Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                    <a  className="read-more">Read more </a><img src={ArrowMark} alt="image" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 card-center-content col-md-6">
                            <div className="card image-devide-contant" >      
                                <img className="card-projects-image" src={CardImage} alt="Card image" />
                                <div className="card-body contant-right-side">
                                    <h4 className="card-title-name">Project name</h4>
                                    <p className="card-parah-name">Location: Place name</p>
                                    <p className="card-text">Description:<br />Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                    <a  className="read-more">Read more </a><img src={ArrowMark} alt="image" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="card image-devide-contant" >      
                                <img className="card-projects-image" src={CardImage} alt="Card image" />
                                <div className="card-body contant-right-side">
                                    <h4 className="card-title-name">Project name</h4>
                                    <p className="card-parah-name">Location: Place name</p>
                                    <p className="card-text">Description:<br />Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                    <a  className="read-more">Read more </a><img src={ArrowMark} alt="image" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Card;