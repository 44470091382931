import React from 'react'

// ************************ import images *********************************
import footerLogo from '../../assets/Icons/footerLogo.svg';
import lingedIn from '../../assets/Icons/lingedIn.svg';
import twitter from '../../assets/Icons/twitter.svg';
import facebook from '../../assets/Icons/facebook.svg';
import { Link } from "react-router-dom";
import './footer.css'

const Footer = () => {
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

  return (
        <>
        <footer className='footer'>

            <div className=" container-fluid">
                <div className='row'>
                    <div className="col-md-6 col-sm-6 col-lg-3" >
                    <Link to="/" onClick={scrollToTop} ><img src={footerLogo} alt="footerLogo" className='footer-logo-icon logo-image-width' /></Link>
                        
                        <div className='pt-4'>
                        <img src={lingedIn} alt="footerLogo" className='footer-logo-icon'/>
                        <img src={twitter} alt="footerLogo" className='twitter-image'/>
                        <img src={facebook} alt="footerLogo" className='instagram-logo-footer' />
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-6 col-lg-3 projects-section-content" >
                        <h4 className="footer-heading-h4 footer-content-project">Projects</h4>
                            <ul className='footer-list-ul footer-ul-left'>
                                <li className='footer-list'>IT/ITES</li>
                                <li className='footer-list'>Hospitals</li>
                                <li className='footer-list'>Data Centers</li>
                                <li className='footer-list'>Corporates</li>
                            </ul>
                            <ul className='footer-list-ul float-left px-3'>
                                <li className='footer-list'>Developers</li>
                                <li className='footer-list'>Retail</li>
                                <li className='footer-list'>Industrial</li>
                                <li className='footer-list'>Retrofit</li>
                            </ul>
                    </div>
                    <div className="col-md-6 col-sm-6 col-lg-2 footer-other-section" >
                        <h4 className="footer-heading-h4">Others</h4>
                            <ul>
                                <li className='footer-list'> About Us</li>
                                <li className='footer-list'> Careers</li>
                            </ul>
                    </div>
                    <div className="col-md-6 col-sm-6 col-lg-4 footer-contact-section" >
                        <h4 className="footer-heading-h4">Contact Us</h4>
                            <div className='contact-left-menus'>
                                <ul>
                                    <li className='footer-list-head pt-2 pb-2'>Mumbai</li>
                                    <li className='footer-list p-0'> PH : 022-61270936/31</li>
                                    <li className='footer-list p-0'>Email : info@pentagon.org.in</li>
                                </ul>
                                <ul>
                                    <li className='footer-list-head pt-2 pb-2'>Pune</li>
                                    <li className='footer-list p-0'> PH : 022-61270936/31</li>
                                    <li className='footer-list p-0'>Email : info@pentagon.org.in</li>
                                </ul>
                            </div>
                            <div className='contact-right-menus'>
                                <ul>
                                    <li className='footer-list-head pt-2 pb-2'>Chennai</li>
                                    <li className='footer-list p-0'> PH : 022-61270936/31</li>
                                    <li className='footer-list p-0'>Email : info@pentagon.org.in</li>
                                </ul>
                                <ul>
                                    <li className='footer-list-head pt-2 pb-2'>Bangalore</li>
                                    <li className='footer-list p-0'> PH : 022-61270936/31</li>
                                    <li className='footer-list p-0'>Email : info@pentagon.org.in</li>
                                </ul>
                            </div>
                    </div>
                </div>
            </div>
        </footer>
        </>
  )
}

export default Footer;