import React, { useState } from 'react'
import projectImage1 from '../../../assets/Images/projectImage1.png';
import projectImage2 from '../../../assets/Images/projectImage2.png';
import projectImage3 from '../../../assets/Images/projectImage3.png';
import projectImage4 from '../../../assets/Images/projectImage4.png';
import projectImage5 from '../../../assets/Images/projectImage5.png';
import projectImage6 from '../../../assets/Images/projectImage6.png';
import projectImage7 from '../../../assets/Images/projectImage7.png';
import projectImage8 from '../../../assets/Images/projectImage8.png';
import projectImage9 from '../../../assets/Images/projectImage9.png';
import { Link } from "react-router-dom";
import './master.css'
import '../Buttons/style.css'

const CardProject = () => {

    const Menu = [
        { 
        images:projectImage1,
        category: 'Corporate', 
        title: "Heading", 
        location: "Place Name", 
        information: "Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua." 
        },
        { 
         images:projectImage2,
         category: 'IT/ITES',
         title: "Heading", 
         location: "Place Name", 
         information: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
         },
         { 
            images:projectImage3,
            
            title: "Heading", 
            location: "Place Name", 
            information: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
            },
            { 
                images:projectImage4,
                category: 'Data centers',
                title: "Heading", 
                location: "Place Name", 
                information: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
                },
                { 
                    images:projectImage5,
                    category: 'Developers',
                    title: "Heading", 
                    location: "Place Name", 
                    information: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
                    },
                    { 
                        images:projectImage6,
                        category: 'Hospitals',
                        title: "Heading", 
                        location: "Place Name", 
                        information: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
                        },
                        { 
                            images:projectImage7,
                            category: 'Retail',
                            title: "Heading", 
                            location: "Place Name", 
                            information: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
                            },
                            { 
                                images:projectImage8,
                                category: 'Industrial',
                                title: "Heading", 
                                location: "Place Name", 
                                information: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
                                },
                                { 
                                    images:projectImage9,
                                    category: 'Retrofit',
                                    title: "Heading", 
                                    location: "Place Name", 
                                    information: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
                                    },
        
        ];
   
    const [button , setButton] = useState(0);
    const [items, setItems] = useState(Menu);
    const filteritems = (categItem) => {
        const updatedItems = Menu.filter((curElem) => {        
            return curElem.category == categItem;      
            });
            setItems(updatedItems);
         }
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
            });
        };
    return (
        <>
            <div className='project-blog-section'>
                <div className='projects-blog-sections'>
                <div className='blog-buttons'>
                        <button className={ button === 0 ? 'active-all' : 'project-buttons'}       
                           onClick={() => {setItems(Menu); setButton(0)}} >All</button>
                        <button className={ button === 1 ? 'active-all' : 'project-buttons'} onClick={() => {filteritems('Corporate'); setButton(1)}} >Corporate</button>
                        <button className={ button === 2 ? 'active-all' : 'project-buttons'} onClick={() => {filteritems('IT/ITES'); setButton(2)}} >IT/ITES</button>
                        <button className={ button === 3 ? 'active-all' : 'project-buttons'} onClick={() => {filteritems('Data centers'); setButton(3)}}>Data centers</button>
                        <button className={ button === 4 ? 'active-all' : 'project-buttons'} onClick={() => {filteritems('Developers'); setButton(4)}} >Developers</button>
                        <button className={ button === 5 ? 'active-all' : 'project-buttons'} onClick={() => {filteritems('Hospitals'); setButton(5)}}>Hospitals</button>
                        <button className={ button === 6 ? 'active-all' : 'project-buttons'} onClick={() => {filteritems('Retail'); setButton(6)}}>Retail</button>
                        <button className={ button === 7 ? 'active-all' : 'project-buttons'} onClick={() => {filteritems('Industrial'); setButton(7)}}>Industrial</button>
                        <button className={ button === 8 ? 'active-all' : 'project-buttons'} onClick={() => {filteritems('Retrofit'); setButton(8)}}>Retrofit</button>
                    </div>
                    <div className='text-end'>
                        <select name="state" className='name-label-blog mt-5'  >
                            <option value="volvo" onClick={() => setItems(Menu)} >All projects</option>
                            <option value="saab" onClick={() => filteritems('Corporate')}> <button>Corporate</button>    </option>
                            <option value="opel" onClick={() => filteritems('IT/ITES')} >IT/ITES</option>
                            <option value="audi" onClick={() => filteritems('Data centers')}>Data centers</option>
                            <option value="saab" onClick={() => filteritems('Developers')}>Developers</option>
                            <option value="saab" onClick={() => filteritems('Hospitals')}>Hospitals</option>
                            <option value="saab" onClick={() => filteritems('Retail')}>Retail</option>
                            <option value="saab" onClick={() => filteritems('Industrial')}>Industrial</option>
                            <option value="saab" onClick={() => filteritems('Retrofit')}>Retrofit</option>
                        </select>
                    </div>
                </div>
            </div>
            <div className='card-blog-post-section'>
                <div className='card-project-section'>
                    <div className='card-image-content'>
                        <div className='row'>
                            {
                                items.map((elem) => {
                                    return (
                                        <div className='col-lg-4 col-md-'>
                                                <Link to="/project-details" onClick={scrollToTop} className="text-color-content">
                                                <div className="cards" >
                                                    <img className="card-img-top mw-100" src={elem?.images} alt="Card image" />
                                                    <div className="card-body">
                                                        <button className="completed mt-3">Completed</button>
                                                        <h4 className='pt-2'>{elem?.title}</h4>
                                                        <h6 className="card-title pt-2"> Location: {elem?.location}</h6>
                                                        <p className="card-text pt-2">{elem?.information}</p>
                                                    </div>
                                                </div>
                                        </Link>
                                            </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CardProject;
