import React from 'react'
import person from '../../../assets/Images/imageOne.png'
import personTwo from '../../../assets/Images/imageTwo.png'
import personTree from '../../../assets/Images/imageTree.png'
import personFour from '../../../assets/Images/imageFour.png'
import personFive from '../../../assets/Images/imageFive.png'
import './style.css'

const RealPeople = () => {
  return (
    <>
        <div className='real-people-page'>
            <div className='container'>
                <h2 className='people-content-heding'>People who made it real</h2>
                <div className='people-image-grid '>
                    <div className='row'>
                        <div className='col-md-6'>
                            <img src={person} alt="person" className='mw-100' />
                            <div className='text-center pt-3'>
                                <h5><b>Name</b></h5>
                                <h6>Position</h6>
                            </div>
                        </div>
                        <div className='col-md-6'>
                        <img src={personTwo} alt="person" className='mw-100' />
                            <div className='text-center pt-3'>
                                <h5><b>Name</b></h5>
                                <h6>Position</h6>
                            </div>
                        </div>
                        <div className='col-md-6 pt-3'>
                        <img src={personTree} alt="person" className='mw-100' />
                            <div className='text-center pt-3'>
                                <h5><b>Name</b></h5>
                                <h6>Position</h6>
                            </div>
                        </div> <div className='col-md-6 pt-3'>
                        <img src={personFour} alt="person" className='mw-100' />
                            <div className='text-center pt-3'>
                                <h5><b>Name</b></h5>
                                <h6>Position</h6>
                            </div>
                        </div> <div className='col-md-6 pt-4 pb-4'>
                        <img src={personFive} alt="person" className='mw-100' />
                            <div className='text-center pt-3'>
                                <h5><b>Name</b></h5>
                                <h6>Position</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default RealPeople;