import React from 'react'
import './style.css'

// ********************************** import assets *************************************
import Bank from '../../../assets/Icons/L&TFinance.svg'
import SunFlower from '../../../assets/Icons/sunFlower.svg'
import ZYCUS from '../../../assets/Icons/ZYCUS.svg'
import BOSCH from '../../../assets/Icons/BOSCH.svg'
import arcserve from '../../../assets/Icons/arcserve.svg'
import DSBBank from '../../../assets/Icons/DSBBank.svg'
import Tesco from '../../../assets/Icons/Tesco.svg'
import accenture from '../../../assets/Icons/accenture.svg'


const OurClients = () => {
  return (
    <>
        <div className='container-fluid'>
            <div className='our-client-section'>
                <h3 className='our-heading4-content'>Our clients</h3>
                <div className='our-client-image-section '>
                    <img src={Bank} alt="image" />
                    <img src={SunFlower} alt="image" />
                    <img src={ZYCUS} alt="image" />
                    <img src={BOSCH} alt="image" />
                    <img src={arcserve} alt="image" />
                    <img src={DSBBank} alt="image" />
                    <img src={Tesco} alt="image" />
                    <img src={accenture} alt="image" />
                </div>
            </div>
        </div>
    </>
  )
}

export default OurClients;
