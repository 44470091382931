import React from 'react'
import awardone from '../../../assets/Icons/award1.svg'
import awardtwo from '../../../assets/Icons/award2.svg'
import awardthree from '../../../assets/Icons/award3.svg'
import awardfour from '../../../assets/Icons/award4.svg'
import './style.css'

const Awards = () => {
  return (
    <>
        <div className='awards'>
            <div className='container'>
                <div className='award-section-page'>
                    <div className='row'>
                        <div className='col-md-12 col-lg-5'>
                            <h3 className='award-heading-left'>Awards</h3>
                            <p className='award-parah-right'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                        </div>
                        <div className='col-md-12 col-lg-7'>
                            <div className='text-center'>
                                <img src={awardone} alt="award" className='awrd-top-left'/>
                                <img src={awardtwo} alt="award" className='awrd-top-right' /><br />
                                <img src={awardthree} alt="award" className='awrd-bottom-left'/>
                                <img src={awardfour} alt="award"className='awrd-buttom-right' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Awards;