import React, { Component } from "react";
import Slider from "react-slick";
import Bank from '../../../assets/Icons/L&TFinance.svg'
import SunFlower from '../../../assets/Icons/sunFlower.svg'
import ZYCUS from '../../../assets/Icons/ZYCUS.svg'
import BOSCH from '../../../assets/Icons/BOSCH.svg'
import arcserve from '../../../assets/Icons/arcserve.svg'
import DSBBank from '../../../assets/Icons/DSBBank.svg'
import Tesco from '../../../assets/Icons/Tesco.svg'
import accenture from '../../../assets/Icons/accenture.svg'
import hcl from '../../../assets/Icons/hcl.svg'
import noral from '../../../assets/Icons/noral.svg'
import './style.css'

const OurClients = () => {

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 9,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows:false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          initialSlide: 0
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      }
    ]
  };

  return (
    <>
      <div className='OurClients-page container-fluid p-1'>
        <h4 className="about-client-heading4">Our clients</h4>
        <div className="slide-image-abut-content">
            <Slider {...settings}>
              <div>
                <img src={Bank} alt="image" />
              </div>
              <div>
                <img src={SunFlower} alt="image" />
              </div>
              <div>
                <img src={ZYCUS} alt="image" />
              </div>
              <div>
                <img src={BOSCH} alt="image" />
              </div>
              <div>
                <img src={arcserve} alt="image" />
              </div>
              <div>
                <img src={DSBBank} alt="image" />
              </div>
              <div>
                <img src={Tesco} alt="image" />
              </div>
              <div>
                <img src={accenture} alt="image" />
              </div>
              <div>
                <img src={hcl} alt="image" />
              </div>
              <div>
                <img src={noral} alt="image" />
              </div>
              <div>
                <img src={accenture} alt="image" />
              </div>
            </Slider>
        </div>
      </div>
    </>
  )
}

export default OurClients;
