import React from 'react'
import projectImage from '../../../assets/Images/projectsBanner.png'
import './style.css'

const Banner = () => {
  return (
    <>
      <div className='projects-section'>
        <div className='banner-projects'>
            <img src={projectImage} alt="banner" className='mw-100 image-blog-banner-res'/>
            <div className='projects-heading-content'>
                <h2 className='heading-project-banner' >Projects</h2>
                <p className='parah-project-banner'>Lorem ipsum dolor sit amet, consectetur adipiscing<br/> elit, sed do eiusmod tempor incididunt ut labore</p>
            </div>
        </div>
      </div>
    </>
  )
}

export default Banner;