import React from 'react'
import blockArrow from '../../../assets/Icons/blockArrow.svg'
import './style.css'

const OurPride = () => {
  return (
        <>
            <div className='our-pride'>
                <div className='ourpride-content-align'>
                    <div className='row'>
                        <div className='col-md-4 col-sm-12 our-contant-left p-0'>
                            <h4 className='our-heading4-contant'>Our pride</h4>
                            <p className='out-prah-contant'>We specialise in HVAC solutions right from the supply, installation, testing, and commissioning to maintenance of the system to the entire satisfaction of our esteemed clients. Our up-to-date industry knowledge and expertise in a dynamically changing field help us to ensure quality service that is consistent with the highest standards.</p>
                        </div>
                        <div className='col-md-8 col-sm-12 pt-3'>
                           
                          <div id="demo" className="carousel slide" data-bs-ride="carousel"> 
                              <div className="carousel-indicators">
                                  <button type="button" data-bs-target="#demo" data-bs-slide-to="0" className="active color-block-contant"></button>
                                  <button type="button" data-bs-target="#demo" data-bs-slide-to="1" className="color-block-contant"></button>
                                  <button type="button" data-bs-target="#demo" data-bs-slide-to="2" className="color-block-contant"></button>
                                  
                              </div>
                              <div className="carousel-inner">
                                  <div className="carousel-item active">
                                      <div className="carousel-caption slider-contant-heading p-0">
                                      <span className='px-3 text-end'><img src={blockArrow} alt="arrow" /></span>
                                          <h3 className='our-pride-slide-contant'>We take a pride in delivering each<br /> project in given timeline with quality<br /> & honoring our EHS commitment.“</h3>
                                          <div className='parah-parent-contant'>
                                              <p className='parah-slide-child-first m-0'>Hitendra Kothakar</p>
                                              <p className='parah-slide-child'>Director Project & Purchase (West)</p>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="carousel-item">
                                      <div className="carousel-caption slider-contant-heading">
                                          <h3 className='our-pride-slide-contant'>We take a pride in delivering each<br /> project in given timeline with quality<br /> & honoring our EHS commitment.“</h3>
                                          <div className='parah-parent-contant'>
                                              <p className='parah-slide-child-first m-0'>Hitendra Kothakar</p>
                                              <p className='parah-slide-child'>Director Project & Purchase (West)</p>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="carousel-item slider-contant-heading">
                                      <div className="carousel-caption slider-contant-heading">
                                          <h3 className='our-pride-slide-contant'>We take a pride in delivering each<br /> project in given timeline with quality<br /> & honoring our EHS commitment.“</h3>
                                          <div className='parah-parent-contant'>
                                              <p className='parah-slide-child-first m-0'>Hitendra Kothakar</p>
                                              <p className='parah-slide-child'>Director Project & Purchase (West)</p>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
  )
}

export default OurPride;