import React from 'react'
import { Field, Form, Formik } from "formik";
import * as Yup from 'yup';
import './style.css'

const FormValidation = () => {

    const SignupSchema = Yup.object().shape({
        name: Yup.string()
            .required("Name is required"),
        
        
       
    });

  return (
    <>
          <div className='form-validate'>
              <div className='container'>
                  <div className='form-valide-content'>
                  <Formik
                    initialValues={{
                        name:'',
                        
                    }}
                    validationSchema={SignupSchema}
                    onSubmit={(values, { resetForm }) => {
                        alert("values");
                        resetForm();
                        console.log(values);
                    }}
                >
                    {({ errors, touched }) => (
                      <Form>
                          <div className='row'>
                              <div className='col-md-6'>
                                  <label className='email-address'>Name*</label><br />
                                 
                                  <Field name="Name" type="text" className="w-100 name-label mt-3" placeholder='Eg: John Krisinski'  />
                                    {errors.name && touched.name ? (
                                        <span>{errors.name}</span>
                                    ) : null}<br></br>
                              </div>
                              <div className='col-md-6'>
                                  <label className='email-address'>Email Address*</label><br />
                                  <input type="text" placeholder='Eg: Johnkrisinski@email.com' name="Name" className='w-100 name-label mt-3' />
                              </div>
                              <div className='col-md-6'>
                                  <label className='email-address mt-3'>Phone Number*</label><br />
                                  <input type="text" placeholder='98765-43210' name="Name" className='w-100 name-label mt-3' />
                              </div>
                              <div className='col-md-6'>
                                  <label className='email-address mt-3'>State*</label><br />
                                  <select name="state" className='w-100 name-label mt-3'  >
                                      <option value="volvo">Select</option>
                                      <option value="saab">Tamilnadu</option>
                                      <option value="opel">Mumbai</option>
                                      <option value="audi">Delhi</option>
                                  </select>
                              </div>
                              <div className='col-md-6'>
                                  <label className='email-address mt-3'>City*</label><br />
                                  <input type="text" placeholder='City' name="Name" className='w-100 name-label mt-3' />
                              </div>
                              <div className='col-md-12'>
                                  <label className='email-address mt-3'>Your Requirement*</label><br />
                                  <textarea name="requirement" id="" cols="130" rows="6" className='w-100 name-label mt-3'></textarea>
                              </div>
                              <div className='text-center mt-4'><button className='form-submit-button'>Submit</button></div>
                          </div>
                      </Form>
                      )}
                    </Formik>  
                  </div>
              </div>
          </div>
    </>
  )
}

export default FormValidation;
