import React from 'react'
import projectImage from '../../../assets/Images/projectDetail.png'
import './style.css'

const ProjectDetailBanner = () => {
  return (
    <>
      <div className='projects-section'>
        <div className='banner-projects'>
          <img src={projectImage} alt="banner" className='mw-100 image-blog-banner' />
          <div className='projects-details-conte'>
            <h2 className='heading-project-banner-details' >Projects Name</h2>
          </div>
        </div>
      </div>
    </>
  )
}

export default ProjectDetailBanner;