import React from 'react'
import galleryImage from '../../../assets/Images/galleryImage.png'
import galleryRight from '../../../assets/Images/galleryRight.png'
import './style.css'

const ProjectDetailsPage = () => {
  return (
    <>
          <div className='blog-project-page'>
              <div className='details-content-blog'>
                  <h6 className='pt-3 heading-content-blog-post'>Location: Place name</h6>
                  <h6 className='pt-3 heading-content-blog-post'>Nature of job:</h6>
                  <h6 className='pt-3 heading-content-blog-post'>Installed Capacity:</h6>
                  <h6 className='pt-3 heading-content-blog-post'>Area:</h6>
                  <h6 className='pt-3 heading-content-blog-post'>Architect:</h6>
                  <h6 className='pt-3 heading-content-blog-post'>HVAC consultant:</h6>
                  <h6 className='pt-3 heading-content-blog-post'>Job status:</h6>
                  <h6 className='pt-3 heading-content-blog-post'>Project description:</h6>
                  <p className='parah-blog-content pt-2'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore <br />et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut<br /> aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum <br />dolore eu fugiat nulla pariatur.</p>
              </div>
              <div className='gallery-post-details'>
                  <h6 className='pt-3 pb-4 heading-content-blog-post'>Gallery</h6>
                  <div>
                      <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
                          <div className="carousel-inner">
                              <div className="carousel-item active">
                                  <img className=" px-2 mobile-image-res" src={galleryImage} alt="First slide" />
                                  <img className=" px-2 mobile-image-res" src={galleryRight} alt="First slide" />
                              </div>
                              <div className="carousel-item">
                                    <img className=" px-2 mobile-image-res" src={galleryImage} alt="First slide" />
                                  <img className=" px-2 mobile-image-res" src={galleryRight} alt="First slide" />
                              </div>
                              <div className="carousel-item">
                                    <img className=" px-2 mobile-image-res" src={galleryImage} alt="First slide" />
                                  <img className=" px-2 mobile-image-res" src={galleryRight} alt="First slide" />
                              </div>
                          </div>
                          <button className="carousel-control-prev preve-slide-scroll" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                              <span className="carousel-control-prev-icon prev-icon slide-click-button-preve" aria-hidden="true"></span>
                          </button>
                          <button className="carousel-control-next next-slide-scroll" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                              <span className="carousel-control-next-icon next-icon-details slide-click-button-next" aria-hidden="true"> </span>
                          </button>
                      </div>
                  </div>
              </div>
          </div>
    </>
  )
}

export default ProjectDetailsPage;