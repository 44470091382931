import React from 'react'
import './style.css'

const FormValidation = () => {
  return (
    <>
          <div className='form-validate'>
              <div className='container'>
                  <div className='form-valide-content'>
                      <form>
                          <div className='row'>
                              <div className='col-md-6'>
                                  <label className='email-address'>First Name*</label><br />
                                  <input type="text" placeholder='Eg: John Krisinski' className='w-100 name-label mt-3' name="Name" />
                              </div>
                              <div className='col-md-6'>
                                  <label className='email-address'>Last Name*</label><br />
                                  <input type="text" placeholder='Eg: John Krisinski' className='w-100 name-label mt-3' name="Name" />
                              </div>
                              <div className='col-md-6'>
                                  <label className='email-address mt-3'>Phone Number*</label><br />
                                  <input type="text" placeholder='98765-43210' name="Name" className='w-100 name-label mt-3' />
                              </div>
                              <div className='col-md-6'>
                                  <label className='email-address mt-3'>Email Address*</label><br />
                                  <input type="text" placeholder='Eg: Johnkrisinski@email.com' name="Name" className='w-100 name-label mt-3' />
                              </div>
                              <div className='col-md-6'>
                                  <label className='email-address mt-3'>City*</label><br />
                                  <input type="text" placeholder='City' name="Name" className='w-100 name-label mt-3' />
                              </div>
                              <div className='col-md-6'>
                                  <label className='email-address mt-3'>State*</label><br />
                                  <select name="state" className='w-100 name-label mt-3'  >
                                      <option value="volvo">Select</option>
                                      <option value="saab">Tamilnadu</option>
                                      <option value="opel">Mumbai</option>
                                      <option value="audi">Delhi</option>
                                  </select>
                              </div>
                              <div className='col-md-6'>
                                  <label className='email-address mt-3'>Resume*</label><br />
                                  <div className="input-group email-address  mb-3 mt-3" for="inputGroupFile02" type="file">
                                      <input type="file" className="form-control upload-file-button" id="inputGroupFile02" />
                                      <label className=" choose-change-color" for="inputGroupFile02">Choose file</label>
                                  </div>                                      
                              </div>
                              <div className='text-center mt-4'><button className='form-submit-button'>Submit</button></div>
                          </div>
                      </form>
                  </div>

              </div>
          </div>
    </>
  )
}

export default FormValidation;
