import React from 'react'
import Banner from  './Banner/index'
// import Buttons from './Buttons/index'
import CardProject from './Cards'

const project = () => {
  return (
    <>
        <div>
            <Banner />
            {/* <Buttons /> */}
            <CardProject />
        </div>
    </>
  )
}

export default project
