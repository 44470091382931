import React from 'react'
import ContactBanner from '../../../assets/Images/contactBanner.png'
import './style.css'


const BannerContact = () => {
  return (
    <>
        <div className='contact-page'>
            <img src={ContactBanner} alt="banner" className='mw-100 image-banner-contact'/>
            <div className='container'>
                <div className='heading-contact-section'>
                    <h2 className='heading4-banner-section '>Contact Us</h2>
                    <p className='paragraph-banners'>Lorem ipsum dolor sit amet, consectetur adipiscing <br />elit, sed do eiusmod tempor incididunt ut labore</p>
                </div>
            </div>
        </div>
    </>
  )
}

export default BannerContact;
